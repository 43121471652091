import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance as axios } from "../../utils/axios";
import { dispatch } from "../store";
import { ENDPOINTS } from "../../config/endpoints";

export interface BannerModel {
  slider_id?: number;
  banner_id: number;
  banner_code?: string;
  banner_type:
    | "skinny_banner"
    | "slider"
    | "static_banner"
    | "carousel_banner"
    | "collection_banner";
  name?: string;
  name_ar?: string;
  name_en?: string;
  reference_type:
    | "product"
    | "tournament"
    | "collection"
    | "bit"
    | "custom"
    | "external"
    | "none";
  reference_id?: number;
  reference_value?: string | any;
  sort_id?: number;
  content_ar?: string;
  content_en?: string;
  cta_ar?: string;
  cta_en?: string;
  web_media_url_ar?: string;
  web_media_url_en?: string;
  mobile_media_url_ar?: string;
  mobile_media_url_en?: string;
  carousel_type?: "static_image" | "timer";
  expire_on?: string;
  is_active?: number;
  is_deleted?: number;
  items?: BannerModel[];
  created_at?: string;
  updated_at?: string;
  connected_with?: any;
}

interface BannerSliceState {
  loading: boolean;
  record?: BannerModel;
  list: BannerModel[];
  count: number;
  params: object;
}

export const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    loading: false,
    list: [],
    count: 0,
    params: {},
  } as BannerSliceState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    getList: (
      state,
      action: PayloadAction<{ count: number; list: BannerModel[] }>
    ) => {
      state.count = action.payload.count;
      state.list = action.payload.list;
      state.loading = false;
    },
    getRecord: (state, action: PayloadAction<BannerModel>) => {
      state.record = action.payload;
      state.loading = false;
    },
    deleteRecord: (state, action: PayloadAction<string | number>) => {
      state.record = undefined;
      state.list = state.list.filter(
        (item) => item.banner_id === action.payload
      );
      state.loading = false;
    },
    activateRecord: (state, action: PayloadAction<string | number>) => {
      if (state.record?.banner_id === action.payload)
        state.record.is_active = 1;
      state.list = state.list.map((item) =>
        item.banner_id === action.payload ? { ...item, is_active: 1 } : item
      );
      state.loading = false;
    },
    deactivateRecord: (state, action: PayloadAction<string | number>) => {
      if (state.record?.banner_id === action.payload)
        state.record.is_active = 0;
      state.list = state.list.map((item) =>
        item.banner_id === action.payload ? { ...item, is_active: 0 } : item
      );
      state.loading = false;
    },
    clearRecord: (state) => {
      state.record = undefined;
      state.loading = false;
    },
    setParams: (state, action: PayloadAction<object>) => {
      state.params = action.payload;
    },
  },
});

export async function setParams(params: object): Promise<void> {
  dispatch(bannerSlice.actions.setParams(params));
}

export async function getAll(params?: object): Promise<void> {
  try {
    dispatch(bannerSlice.actions.startLoading());
    const response = await axios.get(ENDPOINTS.content.banner.data.getAll, {
      params,
    });
    dispatch(bannerSlice.actions.getList(response.data.response));
  } catch (error) {
    dispatch(bannerSlice.actions.stopLoading());
    throw error;
  }
}

export async function getInfo(id: string | number): Promise<void> {
  try {
    dispatch(bannerSlice.actions.startLoading());
    const response = await axios.get(ENDPOINTS.content.banner.data.getInfo, {
      params: { banner_id: id },
    });
    dispatch(bannerSlice.actions.getRecord(response.data.response));
  } catch (error) {
    dispatch(bannerSlice.actions.clearRecord());
    throw error;
  }
}

export async function add(data: any): Promise<number> {
  try {
    dispatch(bannerSlice.actions.startLoading());
    const response = await axios.post(ENDPOINTS.content.banner.data.add, data);
    dispatch(bannerSlice.actions.stopLoading());
    return response.data.response.banner_id;
  } catch (error) {
    dispatch(bannerSlice.actions.stopLoading());
    throw error;
  }
}

export async function update(data: any): Promise<void> {
  try {
    dispatch(bannerSlice.actions.startLoading());
    await axios.post(ENDPOINTS.content.banner.data.update, data);
    dispatch(bannerSlice.actions.stopLoading());
  } catch (error) {
    dispatch(bannerSlice.actions.stopLoading());
    throw error;
  }
}

export async function activate(
  id: string | number,
  slider_id?: number
): Promise<void> {
  try {
    dispatch(bannerSlice.actions.startLoading());
    await axios.post(ENDPOINTS.content.banner.data.activate, {
      banner_id: id,
      slider_id,
    });
    dispatch(bannerSlice.actions.activateRecord(id));
  } catch (error) {
    dispatch(bannerSlice.actions.stopLoading());
    throw error;
  }
}

export async function deactivate(
  id: string | number,
  slider_id?: number
): Promise<void> {
  try {
    dispatch(bannerSlice.actions.startLoading());
    await axios.post(ENDPOINTS.content.banner.data.deactivate, {
      banner_id: id,
      slider_id,
    });
    dispatch(bannerSlice.actions.deactivateRecord(id));
  } catch (error) {
    dispatch(bannerSlice.actions.stopLoading());
    throw error;
  }
}

export async function deleteRecord(
  id: string | number,
  slider_id?: number
): Promise<void> {
  try {
    dispatch(bannerSlice.actions.startLoading());
    await axios.post(ENDPOINTS.content.banner.data.delete, {
      banner_id: id,
      slider_id,
    });
    dispatch(bannerSlice.actions.deleteRecord(id));
  } catch (error) {
    dispatch(bannerSlice.actions.stopLoading());
    throw error;
  }
}
